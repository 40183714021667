import { RemixBrowser } from '@remix-run/react';
import { startTransition } from 'react';
import { hydrateRoot } from 'react-dom/client';
import { PostHogProvider } from 'posthog-js/react';
import { LocaleContextProvider } from './common/providers/LocaleProvider';
import { initializePosthogClient } from './common/utils/posthog';
import { initializeSentryClient } from './common/utils/sentry';
import { posthog } from 'posthog-js';

initializeSentryClient(window.ENV.SENTRY_DSN);
initializePosthogClient(window.ENV.POSTHOG_KEY, window.ENV.POSTHOG_HOST);

const locales = window.navigator.languages;

startTransition(() => {
    hydrateRoot(
        document,
        <LocaleContextProvider locales={locales as string[]}>
            <PostHogProvider client={posthog}>
                <RemixBrowser />
            </PostHogProvider>
        </LocaleContextProvider>
    );
});
