import * as React from 'react';
import * as Sentry from '@sentry/remix';
import { useLocation, useMatches } from '@remix-run/react';
import { prisma } from '~/db.server';

type InitializeSentry = (dsn: string, db?: boolean) => void;

export const initializeSentryClient: InitializeSentry = (dsn, db = false) => {
    Sentry.init({
        environment: process.env.NODE_ENV,
        enabled: process.env.NODE_ENV !== 'development',
        dsn,
        tracesSampleRate: 1,
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.remixRouterInstrumentation(
                    React.useEffect,
                    useLocation,
                    useMatches
                ),
            }),
        ],
    });
};

export const initializeSentryServer: InitializeSentry = (dsn, db = false) => {
    Sentry.init({
        environment: process.env.NODE_ENV,
        enabled: process.env.NODE_ENV !== 'development',
        dsn,
        tracesSampleRate: 1,
        integrations: [
            ...(db ? [new Sentry.Integrations.Prisma({ client: prisma })] : []),
        ],
    });
};
